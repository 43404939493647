import React, {useEffect, useRef, useState} from 'react'
import {Button, message, Upload, UploadFile, UploadProps} from "antd";
import {EyeOutlined, UploadOutlined} from "@ant-design/icons";
import {API_BASE_URL} from "../../Repository/Api";
import {getLocalStorage} from "../../Helper/Helper";
import {ResponseTokenModel} from "../../Model/AuthModel";
import {localStorageKeys} from "../../Constant/LocalStorageKeys";
import {FileModel} from "../../Model/Common";
import {UploadChangeParam} from "antd/es/upload";

interface Props {
    maxCount?: number,
    showRemoveIcon?: boolean,
    onChange?: (value: FileModel[]) => void,
    value?: FileModel[],
    buttonHidden?: boolean,
    multiple?: boolean
    onPreview?: FileModel[],
}

const Uploader: React.FC<Props> = ({maxCount, showRemoveIcon = true, onChange, value, buttonHidden, multiple = false}) => {
    const [fileList, setFileList] = useState<any[]>([])

    useEffect(() => {
        const initData: any[] = value?.map((info, key) => {
            return {
                uid: key,
                name: info.name,
                status: "done",
                url: info.url
            }
        }) || []
        // setStoredFile(initData || [])
        setFileList(initData)
        stored.current = initData
    }, [value])

    const stored = useRef<any[]>([])

    const handleChange: UploadProps['onChange'] = async (info) => {
        const { status } = info.file;

        setFileList(info.fileList)

        if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            const file = info.file
            file.url = info.file.response?.url
            stored.current.push(file)
        } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }

        if(stored.current.length === info.fileList.length){
            setFileList(stored.current)
            onChange && onChange(stored.current.map(file => {
                return {
                    name: file.name,
                    url: file.url
                }
            }))
            stored.current = []
        } else {
            setFileList(info.fileList)
        }
    }
    const props: UploadProps = {
        name: 'userfile',
        action: `${API_BASE_URL}/system/upload`,
        headers: {
            authorization: `Bearer ${getLocalStorage<ResponseTokenModel>(localStorageKeys.tokenKey)?.access_token || "asdf123456cf"}`,
        },
        onChange: (info)=>{
            handleChange(info)
        },
        onRemove: (file) => {
            onChange && onChange(value?.filter(info => info.url !== file.url) || [])
        },
        onPreview: (file: any) => {
          // const url = '/api/system/download/' + btoa(file.url)
          const url = file.url;
          window.open(url);
        },
        multiple: multiple,
        fileList: fileList
    };

    return (
        <Upload {...props} showUploadList={{showRemoveIcon: showRemoveIcon}} maxCount={maxCount}>
            {!buttonHidden && <Button icon={<UploadOutlined/>}>파일선택</Button>}
        </Upload>
    )
}

export default Uploader